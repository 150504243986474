$( document ).ready(function() {
	//DOB
    $('#dob-month, #dob-day, #dob-year').bind('keypress blur', function() {
	    $('#00N0a00000DGVVd').val($('#dob-month').val() + '/' +
	    	$('#dob-day').val() + '/' +
			$('#dob-year').val() );
	});
	//Name
	$('#00N0a00000DGViV').bind('keypress blur', function() {
	    $('#first_name').val($('#00N0a00000DGViV').val());
	});
	$('#00N0a00000DGVjg').bind('keypress blur', function() {
	    $('#last_name').val($('#00N0a00000DGVjg').val());
	});


	$('.stories-carousel').slick({
		dots: false,
		arrows: true,
		nextArrow: '<button type="button" class="slick-next"><svg width="16" height="32"><use xlink:href="#tangram-triangle-right" class="scroll-right" /></svg></button>',
		prevArrow: '<button type="button" class="slick-prev"><svg width="16" height="32"><use xlink:href="#tangram-triangle-left"  class="scroll-left" /></svg></button>',
		infinite: false,
		speed: 1000,
		slidesToShow: 6,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 625,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 400,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}

		]
	});
	
	var $hamburger = $(".hamburger");
	$hamburger.on("click", function(e) {
		$hamburger.toggleClass("is-active");
		$("#mobile-nav").slideToggle(500);
	});
	
	//smoothscroll
	// Select all links with hashes
	$('a[href*="#"]')
	  // Remove links that don't actually link to anything
	  .not('[href="#"]')
	  .not('[href="#0"]')
	  .click(function(event) {
	    // On-page links
	    if (
	      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
	      && 
	      location.hostname == this.hostname
	    ) {
	      // Figure out element to scroll to
	      var target = $(this.hash);
	      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
	      // Does a scroll target exist?
	      if (target.length) {
	        // Only prevent default if animation is actually gonna happen
	        event.preventDefault();
	        $('html, body').animate({
	          scrollTop: target.offset().top
	        }, 1000, function() {
	          // Callback after animation
	          // Must change focus!
	          var $target = $(target);
	          $target.focus();
	          if ($target.is(":focus")) { // Checking if the target was focused
	            return false;
	          } else {
	            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
	            $target.focus(); // Set focus again
	          };
	        });
	      }
	    }
	  });
	
	// toggle q and a section
	$('.qanda').find('.question').click(function(){
		//Expand or collapse this panel
		$(this).next().slideToggle('slow');
		//Hide the other panels
		$(".answer").not($(this).next()).slideUp('slow');
	});
	

});